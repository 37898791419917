export default [
  {
    path: '/settings/manage-admin/:admin',
    name: 'manage-admin-detail',
    component: () => import('@/views/settings/ManageAdminDetail.vue'),
    meta: {
      pageTitle: 'จัดการแอดมิน',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'จัดการแอดมิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/manage-admin',
    name: 'manage-admin',
    component: () => import('@/views/settings/ManageAdmin.vue'),
    meta: {
      pageTitle: 'จัดการแอดมิน',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'จัดการแอดมิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/promotions/:promotionId',
    name: 'setting-promotion-detail',
    component: () => import('@/views/settings/ManagePromotionDetail.vue'),
    meta: {
      pageTitle: 'ตั้งค่าโปรโมชั่น',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ตั้งค่าโปรโมชั่น',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/promotions',
    name: 'setting-promotion',
    component: () => import('@/views/settings/ManagePromotion.vue'),
    meta: {
      pageTitle: 'ตั้งค่าโปรโมชั่น',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ตั้งค่าโปรโมชั่น',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/settings/system',
    name: 'setting-system',
    component: () => import('@/views/settings/SystemSettings.vue'),
    meta: {
      pageTitle: 'ตั้งค่าระบบ',
      breadcrumb: [
        {
          text: 'ตั้งค่า',
        },
        {
          text: 'ระบบ',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
]
