export default [
  {
    path: '/dashboard/summary',
    name: 'dashboard',
    // component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  // {
  //   path: '/dashboard/summary',
  //   name: 'dashboard-summary',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  // },
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
]
