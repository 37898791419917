module.exports = {
  SA: {
    name: 'SA Gaming',
    url: 'https://www.sa-bo.net/',
    vpn: false,
  },
  SEXY: {
    name: 'SEXY',
    url: 'https://ag.onlinegames22.com/',
    vpn: false,
  },
  WM: {
    name: 'WM Casino',
    url: 'https://ag.win66.org',
    vpn: true,
  },
  DG: {
    name: 'Dream Gaming',
    url: 'https://ag.dg66.info/ag/login.html',
    vpn: true,
  },
  JOKER: {
    name: 'JOKER',
    url: 'http://www.partnerbo68.net',
    vpn: false,
  },
  PG: {
    name: 'PG',
    url: 'http://www.pg-bo.net/?code=',
    vpn: true,
  },
  CQ9: {
    name: 'CQ9',
    url: 'https://booe.cqgame.cc/',
    vpn: true,
  },
  SBO: {
    name: 'SBO BET',
    url: 'https://admin.sswwkk.com/login',
    vpn: false,
  },
}
