import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://staffv2.sagameclub88.com/api/',
  baseURL: `${location.href.split(':')[0]}://${location.host}/api/richs/v2`,
  timeout: 60000,
  // validateStatus: status => status < 500,
  // headers: { Authorization: `Bearer ${l}` },
})

axiosIns.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  if (localStorage.getItem('accessToken')) config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return config
}, null, { synchronous: true })

let errCount = 0
axiosIns.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    errCount += 1
    if (errCount > 4) {
      localStorage.clear()
      location.href = '/'
    }
  }
  return error
})

// axiosIns.interceptors.response.use(re)
Vue.prototype.$http = axiosIns
Vue.prototype.bankListOptions = []
axiosIns.get('bankaccounts').then(({ data }) => {
  Vue.prototype.bankListOptions = data
  // localStorage.setItem('bank-lists', JSON.stringify(data))
  localStorage.setItem('bank-lists', JSON.stringify([{ text: 'กรุงเทพ', value: '002' }, { text: 'ทรูมันนี่', value: 'TMW' }, { text: 'กสิกรไทย', value: '004' }, { text: 'กรุงไทย', value: '006' }, { text: 'ออมสิน', value: '030' }, { text: 'กรุงศรีฯ', value: '025' }, { text: 'ธกส', value: '034' }, { text: 'ทหารไทยธนชาต', value: '011' }, { text: 'ไทยพาณิชย์', value: '014' }, { text: 'ไอซีบีซี', value: '070' }, { text: 'ไทยเครดิต', value: '071' }, { text: 'ซิติ้แบงก์', value: '017' }, { text: 'ซูมิโตโม', value: '018' }, { text: 'สแตนดาร์ดชาร์เต', value: '020' }, { text: 'ซีไอเอ็มบี', value: '022' }, { text: 'ยูโอบี', value: '024' }, { text: 'เอชเอสบีซี', value: '031' }, { text: 'มิซูโฮ', value: '039' }, { text: 'ธอส', value: '033' }, { text: 'แลนด์แอนด์เฮ้าส', value: '073' }, { text: 'ทิสโก้', value: '067' }, { text: 'เกียรตินาคิน', value: '069' }, { text: 'อิสลาม', value: '066' }]))
})
export default axiosIns
