export default [
  {
    path: '/support/audit-admin',
    name: 'support-audit-admin',
    component: () => import('@/views/support/AuditActivities.vue'),
    meta: {
      pageTitle: 'ตรวจสอบการใช้งาน',
      breadcrumb: [
        {
          text: 'ระบบ',
        },
        {
          text: 'ตรวจสอบการใช้งาน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
]
