export default [
  {
    path: '/members/list',
    name: 'member-list',
    component: () => import('@/views/members/MemberList.vue'),
    meta: {
      pageTitle: 'รายชื่อลูกค้า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'รายชื่อลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/members/create',
    name: 'member-create',
    component: () => import('@/views/members/MemberCreate.vue'),
    meta: {
      pageTitle: 'เพิ่มสมาชิคใหม่',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'เพิ่มสมาชิคใหม่',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/members/:user',
    name: 'member-detail',
    component: () => import('@/views/members/MemberDetail.vue'),
    meta: {
      pageTitle: 'รายชื่อลูกค้า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'รายชื่อลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-wallet',
    name: 'member-wallet-txns',
    component: () => import('@/views/members/MemberWallet.vue'),
    meta: {
      pageTitle: 'รายการเคลื่อนไหวกระเป๋าเงิน',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'รายการเคลื่อนไหวกระเป๋าเงิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-wallet/:user',
    name: 'member-wallet-txns',
    component: () => import('@/views/members/MemberWallet.vue'),
    meta: {
      pageTitle: 'รายการเคลื่อนไหวกระเป๋าเงิน',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'รายการเคลื่อนไหวกระเป๋าเงิน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-bets',
    name: 'member-bet-txns',
    component: () => import('@/views/members/MemberBetDetail.vue'),
    meta: {
      pageTitle: 'รายการแทงของลูกค้า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'รายการแทงของลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-bets/:user',
    name: 'member-bet-txns',
    component: () => import('@/views/members/MemberBetDetail.vue'),
    meta: {
      pageTitle: 'รายการแทงของลูกค้า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'รายการแทงของลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-promotion',
    name: 'member-promotion',
    component: () => import('@/views/members/MemberPromotion.vue'),
    meta: {
      pageTitle: 'การรับโปรโมชั่นของลูกค้่า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'การรับโปรโมชั่นของลูกค้่า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-promotion/:user',
    name: 'member-promotion',
    component: () => import('@/views/members/MemberPromotion.vue'),
    meta: {
      pageTitle: 'การรับโปรโมชั่นของลูกค้า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'การรับโปรโมชั่นของลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-credit',
    name: 'member-credit',
    component: () => import('@/views/members/MemberCredit.vue'),
    meta: {
      pageTitle: 'จัดการเครดิตของลูกค้า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'จัดการเครดิตของลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-affiliate',
    name: 'member-affiliate',
    component: () => import('@/views/members/MemberAffiliate.vue'),
    meta: {
      pageTitle: 'แนะนำเพื่อน',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'แนะนำเพื่อน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-affiliate/:user',
    name: 'member-affiliate',
    component: () => import('@/views/members/MemberAffiliate.vue'),
    meta: {
      pageTitle: 'แนะนำเพื่อน',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'แนะนำเพื่อน',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
  {
    path: '/member-credit/:user',
    name: 'member-credit',
    component: () => import('@/views/members/MemberCredit.vue'),
    meta: {
      pageTitle: 'จัดการเครดิตของลูกค้า',
      breadcrumb: [
        {
          text: 'ข้อมูลลูกค้า',
        },
        {
          text: 'จัดการเครดิตของลูกค้า',
          active: true,
        },
      ],
    //   resource: 'ACL',
    //   action: 'read',
    },
  },
]
