/* eslint-disable import/no-dynamic-require */
import Vue from 'vue'
import firebase from 'firebase/app'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'
import i18n from '@/libs/i18n'
import axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

const firebaseConfig = {
  apiKey: 'AIzaSyBJiGkv6uVpJgNVVvOpNX_pdDxHd_v4G34',
  authDomain: 'richs-34900.firebaseapp.com',
  databaseURL: 'https://richs24.asia-southeast1.firebasedatabase.app/',
  projectId: 'richs-34900',
  storageBucket: 'richs-34900.appspot.com',
  messagingSenderId: '388703013985',
  appId: '1:388703013985:web:f0fff597337c946c75ae20',
  measurementId: 'G-VXYRGHZT5Y',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// firebase.analytics()
Vue.prototype.numberWithCommas = x => {
  let ret = x || 0
  if (x.toFixed)
    ret = x.toFixed(2)
  ret = ret.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return ret
}
Vue.prototype.formatDateBKK = (srcDate, srcFmt = 'YYYY-MM-DD HH:mm:ss', destFmt = 'DD/MM HH:mm') => moment(srcDate, srcFmt).add(7, 'hours').format(destFmt)
Vue.prototype.formatDate = (srcDate, srcFmt = 'YYYY-MM-DD HH:mm:ss', destFmt = 'DD/MM HH:mm') => moment(srcDate, srcFmt).format(destFmt)
// eslint-disable-next-line global-require
Vue.prototype.getBankImage = bankCode => require(`./assets/images/banks/${(`${bankCode || ''}`).toLowerCase()}.png`)
// Vue.prototype.getIP = () => ipAPI

try {
  axios.get('https://pro.ip-api.com/json/?key=jXZCe3nzvoLmJ15').then(data => {
    sessionStorage.setItem('user-insight', JSON.stringify(data.data))
    sessionStorage.setItem('user-meta', encodeURIComponent(`${data.data.query}|${data.data.lat}x${data.data.lon}|${data.data.isp}|${data.data.org}`))
  }).catch(() => {})
} catch (err) {
  console.log('ip api error', err)
}

Vue.prototype.getAPPCode = () => {
  const lists = {
    superrich66: 'SPR66',
    lkc888: 'LKC888',
    casinoclub7: 'CA7',
    casinoclub777: 'CA7',
    saclub7: 'SAC7',
    saclub77: 'SAC7',
    saclub7vip: 'SAC7',
    sagameclub88: 'SGC88',
    richking66: 'RK66',
    saclub888: 'SAC888',
    mamaigame: 'MAMAI',
    kerry77: 'KERRY77',
    clubbet77: 'CB77',
    superrich24: 'SPR24',
    latphrao88: 'LP88',
    168: 'SAC7',
    sacomp: 'SACOMP',
    wnbet777: 'WNB777',
    wnbet168: 'WNB168',
    lottolove999: 'LTLV999',
    ufaclub8888: 'UFA8888',
    clubbet55: 'CB55',
    saclub999: 'SAC999',
    ufafet: 'UFAFET',
  }

  if (location.host.split('.').slice(1, 2).pop() === 'richs')
    return lists[location.host.split('.').slice(0, 1).join('.')]

  return lists[location.host.split('.').slice(1, 2).join('.')]
}
